import React from 'react';

import { ModuleWrapper } from '../moduleWrapper';
import { FeatureCard } from '@components/cards/featureCard';

const CardGrid = ({ config, items, title }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="px-nav-gutter">
        {title && <h2 className="isH2">{title}</h2>}
        <div className="grid gap-y-4 md:grid-cols-3 md:gap-4 lg:gap-6">
          {items.map((item) => (
            <FeatureCard {...item} aspectRatio="portrait" />
          ))}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default CardGrid;
